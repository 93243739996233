@import './breakpoints';

.mudal {
  left: 0;
  top: 0;
}

#season-campaign-wrapper {
  width: 100%;
  height: 100%;
}

.season-campaign {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  .close-button {
    background-color: #fff;
    border-radius: 50px;
    color: #000;
    width: 35px;
    height: 35px;
    position: absolute;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 87%;
    top: 12px;
    font-size: 21px;
    font-weight: 500;
    cursor: pointer;
  }

  &-wrapper {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__terms_full-cover {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: calc(100% - 100px);
    top: 0;
    left: 0;
    padding: 60px;
    text-align: left;
    display: none;
    overflow: hidden;
  }

  &__terms-text {
    overflow: scroll;
    height: 100%;
  }

  &__back {
    font-size: 18px;
    color: #f8dc60;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__show {
    display: block;
    animation: fadeIn 1s forwards;
    opacity: 1;
  }

  &__hide {
    animation: fadeOut 1s forwards;
    display: none;
    opacity: 0;
  }

  &__overlay {
    background-color: black;
    opacity: 0.3;
    width: 100%;
    height: 100%;
  }

  &__button {
    cursor: pointer;
    width: 90%;
  }

  &__container {
    width: 90%;
    height: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #ed372b;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    filter: drop-shadow(0 0 0.75rem black);
    overflow-y: scroll;
    overflow-x: hidden;

    .heart {
      width: 70px;
      height: 50px;
      z-index: 1;
      background-repeat: no-repeat;
      position: absolute;
      background-size: contain;

      &--left {
        background-image: url('/assets/img/valentines/rizk-heart-left.png');
      }

      &--right {
        background-image: url('/assets/img/valentines/rizk-heart-right.png');
      }

      &--1 {
        left: 20px;
        top: 11px;
      }

      &--2 {
        top: 73%;
        left: 91%;
      }

      &--3 {
        top: 36%;
        left: -5px;
      }

      &--4 {
        top: 89%;
        left: -15px;
        width: 50px;
        height: 45px;
      }

      &--5 {
        top: 66%;
        left: 47%;
        width: 25px;
        height: 25px;
      }

      &--6 {
        left: 89%;
        top: 100px;
        width: 50px;
        height: 30px;
      }

      &--7 {
        top: 70px;
        left: 78%;
        width: 60px;
        height: 40px;
        transform: rotate(55deg);
      }
    }

    &__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    animation: fadeIn 0.5s forwards;

    &.season-campaign__hide {
      display: none;
    }
  }

  &__right {
    width: 100%;
    animation: fadeIn 0.5s forwards;
  }

  &__terms {
    a {
      color: white;
      font-weight: 600;
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    justify-content: space-around;
  }

  &__logo {
    width: 300px;
    margin-bottom: 20px;

    &-img {
      width: 100%;
    }
  }

  &__text,
  &__text-bold {
    margin-bottom: 10px;
    color: black;
    font-size: 18px;
  }

  &__text-bold {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__decline-text {
    color: #fdcd08;
    margin: 5px 0px 0px;
    cursor: pointer;
  }

  &__promo-text {
    color: black;
    font-size: 12px;
  }

  &__img {
    width: 48%;

    &:hover {
      filter: drop-shadow(0 0 0.75rem #ffe502);
      cursor: pointer;
    }
  }

  &__claim-pop-up {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;

    &.fadeIn {
      display: block;
      animation: fadeIn 1s forwards;
      opacity: 1;
    }

    .scaleUp {
      animation: scaleUp 1s forwards;
    }

    .season-campaign__overlay {
      opacity: 0.8;
    }

    .season-campaign__choosen {
      width: 360px;
    }

    &__text {
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      width: 60%;
    }

    .season-campaign__overlay-glow {
      position: absolute;
      top: calc(50% - 47px);
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #fff;
      animation: glowBounce 3s ease-in-out infinite;
      box-shadow:
        0 0 60px 30px #fff,
        0 0 100px 60px #f0db56,
        0 0 140px 90px #ed372b;
    }

    .btn--rewards {
      font-size: 15px;
      padding: 10px;
      width: max-content;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__pop-up {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #ffe501;
  }
}

.tablet({
    .season-campaign .close-button {
        left: 92%;
    }
});

.desktop ({
    .heart--3, .heart--4 {
        display: block;
    }

    .season-campaign {
        &__images {
            overflow: visible;
            height: inherit;
            margin-top: -30px;
        }
    }

});

.desktop-large ({


.season-campaign {

    .close-button {
        width: 45px;
        height: 45px;
        left: 94%;
        top: 30px;
        font-size: 22px;
        font-weight: 600;
    }

    &__left {
        width: 40%;
        padding: 0 17px;
    }

    &__right {
        width: 60%;
    }

    &__logo {
        width: 450px;
    }

    &__text, &__text-bold {
        margin-bottom: 20px;
        padding: 0 20px;
    }

    &__img {
        width: 280px;
        max-width: 48%;
    }

    &__button {
        width: 60%;
    }

    &__terms_full-cover {
        padding: 100px;
    }

    &__container {
        flex-direction: row;
        padding: 70px;
        width: 80%;
        height: 80%;
        gap: 30px;
        overflow: visible;
        align-items: center;
        max-width: 1250px;

        .heart {
            width: 100px;
            height: 80px;

            &--1 {
                left: 20px;
                top: 30px;
            }

            &--2 {
                top: 70%;
                left: -30px;
            }

            &--3 {
                top: 86%;
                left: 215px;
            }

            &--4 {
                top: 86%;
                left: 315px;
                width: 110px;
                height: 60px;
            }

            &--5 {
                top: 83%;
                left: 92%;
                width: 80px;
                height: 70px;
            }

            &--6 {
                left: 92%;
                top: 110px;
                width: 70px;
                height: 50px;
            }

            &--7 {
                top: -30px;
                left: 65%;
                transform: rotate(0deg);
            }
        }
    }
}
});

@media only screen and (max-height: 700px) and (orientation: landscape) {
  .season-campaign {
    .close-button {
      left: 94%;
    }
  }
  .heart--5 {
    display: none;
  }
  .season-campaign__claim-pop-up {
    .season-campaign {
      &__choosen {
        width: 187px;
      }
    }
    .season-campaign {
      &__overlay-glow {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@keyframes glowBounce {
  0% {
    transform-origin: left top;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(-360deg);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    left: 99999px;
  }
  100% {
    left: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
